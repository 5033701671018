import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import Check from './Check';

import s from './Table.scss';

export default class Table extends PureComponent {

  static propTypes = {
    items: PropTypes.array,
    checked: PropTypes.bool,
  }

  static defaultProps = {
    items: [],
  }

  render() {
    const { items, checked } = this.props;
    // checked should be property of item (item.checked)

    return (
      <div className={s.table}>
        {items.map(item => (
          <div key={item} className={s.table__row}>
            {item}
            {checked && <div className={s.table__check}><Check /></div>}
          </div>
        ))}
      </div>
    );
  }
}
