import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import { Row } from 'components/layout';
import Table from 'components/table';

import s from './Data.scss';

export default class Data extends PureComponent {

  static propTypes = {
    heading: PropTypes.string,
    text: PropTypes.string,
    rows: PropTypes.array,
  }

  static defaultProps = {
    heading: '',
    text: '',
    rows: [],
  }

  render() {
    const { heading, text, rows } = this.props;

    return (
      <div className={s.data}>
        <div className={s.data__container}>
          <Row>
            <div className={s.data__content}>
              <h2 className={s.data__heading}>{heading}</h2>
              <p className={s.data__text}>{text}</p>
            </div>
            <div className={s.data__table}>
              <Table items={rows} checked />
            </div>
          </Row>
        </div>
        <hr />
      </div>
    );
  }
}
