import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Container, Row } from 'components/layout';
import Image from 'components/image';
import Button from 'components/button';

import s from './Gdpr.scss';

export default class Gdpr extends PureComponent {

  static propTypes = {
    heading: PropTypes.string,
    text: PropTypes.string,
    image: PropTypes.shape({
      src: PropTypes.string,
      alt: PropTypes.string,
      width: PropTypes.number,
      height: PropTypes.number,
    }),
    ctaText: PropTypes.string,
    ctaUrl: PropTypes.string,
  }

  static defaultProps = {
    heading: '',
    text: '',
    image: {},
    ctaText: 'Read more',
    ctaUrl: null,
  }

  render() {
    const { heading, text, image, ctaText, ctaUrl } = this.props;

    return (
      <div className={s.gdpr}>
        <Container>
          <Row>
            {image &&
              <div className={s.gdpr__imageWrap}>
                <div className={s.gdpr__image}>
                  <Image
                    src={image.src}
                    alt={image.alt}
                    width={image.width}
                    height={image.height}
                  />
                </div>
              </div>
            }
            <div className={s.gdpr__content}>
              <div>
                {heading &&
                  <h2 className={s.gdpr__heading}>
                    {heading}
                  </h2>
                }
                {text &&
                  <p className={s.gdpr__text}>
                    {text}
                  </p>
                }
                {ctaUrl &&
                  <Button to={ctaUrl} color="transparent">
                    {ctaText}
                  </Button>
                }
              </div>
            </div>
          </Row>
        </Container>
      </div>
    );
  }
}
