import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import _get from 'lodash/get';
import LazyLoad from 'react-lazyload';

import extractMeta from 'utils/extract-meta';
import Modules from 'containers/modules';

import HeroIntro from 'components/hero-intro';
import Table from 'components/table';
import Certifications from 'components/certifications';

import Tokenization from './components/tokenization';
import Gdpr from './components/gdpr';
import Data from './components/data';
import SEO from 'components/seo';

export default class Security extends PureComponent {

  static propTypes = {
    data: PropTypes.object.isRequired,
  }

  render() {
    const { page } = this.props.data;
    const { pathname } = this.props.location;

    return (
      <Fragment>
        <SEO pathname={pathname} {...extractMeta(page)} article />
        <HeroIntro
          heading={page.heroHeading}
          text={page.heroText}
        />
        <LazyLoad once offset={500}>
          <Modules list={[page.securityImage]}>
            <Table items={page.securityTable} />
          </Modules>
        </LazyLoad>
        <Tokenization
          heading={page.credentialHeading}
          image={{
            src: _get(page.credentialImage, 'file.url'),
            alt: _get(page.credentialImage, 'description'),
            width: _get(page.credentialImage, 'file.details.image.width'),
            height: _get(page.credentialImage, 'file.details.image.height'),
          }}
          text={_get(page.credentialText, 'text')}
          text2={_get(page.credentialText2, 'text')}
        />
        <LazyLoad once offset={500}>
          <Certifications
            heading={page.certificationsHeading}
            text={_get(page.certificationsText, 'text')}
            certifications={page.certificationsList.map(certification => ({
              image: {
                src: _get(certification, 'image.file.url'),
                alt: _get(certification, 'image.description'),
                width: _get(certification, 'image.file.details.image.width'),
                height: _get(certification, 'image.file.details.height'),
              },
              heading: _get(certification, 'heading'),
              text: _get(certification, 'text.text'),
            }))}
          />
        </LazyLoad>
        <LazyLoad once offset={500}>
          <Gdpr
            image={{
              src: _get(page.gdprImage, 'file.url'),
              alt: _get(page.gdprImage, 'description'),
              width: _get(page.gdprImage, 'file.details.image.width'),
              height: _get(page.gdprImage, 'file.details.image.height'),
            }}
            heading={page.gdprHeading}
            text={_get(page.gdprText, 'text')}
            ctaText={page.gdprCtaText}
            ctaUrl={page.gdprCtaUrl}
          />
        </LazyLoad>
        <Data
          heading={page.dataHeading}
          text={_get(page.dataText, 'text')}
          rows={page.dataTable}
        />
        <LazyLoad once offset={500}>
          <Modules list={[page.detailsIconSet]} />
        </LazyLoad>
        <Modules list={[page.cta]} />
      </Fragment>
    );
  }
}
