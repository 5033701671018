import React from 'react';

import Icon from 'components/icon';

import s from './Check.scss';

const Check = () => (
  <i className={s.check}>
    <Icon id="check" />
  </i>
);

export default Check;
