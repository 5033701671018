import { graphql } from 'gatsby';
import Security from 'routes/security/Security';

export default Security;

export const query = graphql`
  query Security {
    page: contentfulPageSecurity {
      pageTitle
      metaDescription
      metaImage {
        ...image
      }
      heroHeading: heroTitle
      heroText: heroBody
      securityImage: heroImage {
        ...moduleList
      }
      securityTable
      credentialHeading: credentialTitle
      credentialImage {
        ...image
      }
      credentialText: credentialBody {
        text: credentialBody
      }
      # credentialText2: credentialBody2 {
      #   text: credentialBody2
      # }
      # biometricsImage {
      #   ...moduleList
      # }
      # biometricsText: biometricsBody2 {
      #   text: biometricsBody2
      # }
      certificationsHeading: certificationsTitle
      certificationsText: certificationsBody {
        text: certificationsBody
      }
      certificationsList {
        ...submoduleList
      }
      gdprImage {
        ...image
      }
      gdprHeading: gdprTitle
      gdprText: gdprBody {
        text: gdprBody
      }
      gdprCtaText
      gdprCtaUrl
      dataHeading: dataIntroTitle
      dataText: dataBody {
        text: dataBody
      }
      dataTable
      detailsIconSet {
        ...moduleList
      }
      cta {
        ...moduleList
      }
    }
  }
`;
