import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import { Container } from 'components/layout';
import Image from 'components/image';
import Markdown from 'components/markdown';

import s from './Tokenization.scss';

export default class Tokenization extends PureComponent {

  static propTypes = {
    heading: PropTypes.string,
    image: PropTypes.shape({
      src: PropTypes.string,
      alt: PropTypes.string,
      width: PropTypes.number,
      height: PropTypes.number,
    }),
    text: PropTypes.string,
    text2: PropTypes.string,
  }

  static defaultProps = {
    heading: '',
    image: {},
    text: '',
    text2: '',
  }

  render() {
    const { heading, image, text, text2 } = this.props;

    return (
      <div className={s.tokenization}>
        <Container>
          <h1 className={s.tokenization__heading}>{heading}</h1>
          {image &&
            <div className={s.tokenization__imageWrap}>
              <div className={s.tokenization__image}>
                <Image
                  src={image.src}
                  alt={image.alt}
                  width={image.width}
                  height={image.height}
                />
              </div>
            </div>
          }
          <div className={s.tokenization__content}>
            <Markdown className={s.tokenization__text} source={text} />
            <Markdown className={s.tokenization__list} source={text2} />
          </div>
        </Container>

        <hr className={s.tokenization__hr} />
      </div>
    );
  }
}
